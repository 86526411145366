<template>
  <!-- 劳务众包用工管理系统-->
  <div class="wrapper">
    <Header></Header>
    <div class="body">
      <div class="pic">
        <img :src="page3">
      </div>
      <div class="link">
        <div class="title">
          <div class="title-big">颠覆传统用工</div>
          <div class="title-small">为您节省更多的用工成本</div>
        </div>
        <div class="forms1" v-for="(form, index) in forms1" :key="index">
          <div class="form1">
            <div class="form1_icon">
              <img class="form1_img" :src="form.img">
            </div>
            <div class="form1_solid"></div>
            <div class="form1_content">
              <div class="form1_title">{{form.title}}</div>
              <div class="form1_text">{{form.text}}</div>
            </div>
          </div>
        </div>
        <Reserve></Reserve>
      </div>
      <div class="link2">
        <div class="link2_title">
          <div class="link2_title-big">四大服务帮你彻底解决用工难题</div>
          <div class="link2_title-small">让您的用工更轻松</div>
        </div>
        <div class="forms2">
          <div class="form2" v-for="(form,index) in forms2" :key=index>
              <div class="form2_icon">
                <img class="form2_img" :src="form.img">
              </div>
              <div class="form2_content">
                <div class="form2_title">{{form.title}}</div>
                <div class="form2_text">{{form.text}}</div>
              </div>
          </div>
        </div>
        <Reserve></Reserve>
      </div>
      <Record></Record>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Reserve from '../components/reserve.vue'
import Record from '../components/record.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'page1',
  components: {
    Header,
    Reserve,
    Record,
    Footer
  },
  data () {
    return {
      page3: require('@/assets/img/page3.png'),
      forms1: [{
        img: require('@/assets/img/page3.2.1.png'),
        title: '招募零工',
        text: '发布招募信息，平台提供大量零工资源，助力企业找到合适的业务人员'
      }, {
        img: require('@/assets/img/page3.2.2.png'),
        title: '产品管理',
        text: '管理企业产品，业务员可以直接去销售这些产品，给企业提交业绩'
      }, {
        img: require('@/assets/img/page3.2.3.png'),
        title: '业绩管理',
        text: '企业可以直接给业务员添加业绩，也可以审核零工添加的业绩'
      }, {
        img: require('@/assets/img/page3.2.4.png'),
        title: '奖励管理',
        text: '制定奖励规则，业务员提交的业绩/任务/行为会根据奖励规则生成奖励记录'
      }, {
        img: require('@/assets/img/page3.2.5.png'),
        title: '结算管理',
        text: '企业可以在线结算业务员的奖励，所有账务清晰可查'
      }, {
        img: require('@/assets/img/page3.2.6.png'),
        title: '营销行为管理',
        text: '记录业务员行为，例如打卡、拜访客户、电销、日报月报之类的，可以给予行为奖励'
      }, {
        img: require('@/assets/img/page3.2.7.png'),
        title: '任务管理',
        text: '企业发布任务由业务员在线接任务，完成任务后，给企业提交工作成果'
      }],
      forms2: [{
        img: require('@/assets/img/page3.3.1.png'),
        title: '外包业务员托管服务',
        text: '外包企业（商户）委托平台提供业务员（众包）灵活用工场景平台服务，外包企业（商户）通过平台进行云销售的管理和营销业务分包；'
      }, {
        img: require('@/assets/img/page3.3.2.png'),
        title: '产品服务营销外包',
        text: '外包企业（商户），将产品服务外包给平台进行营销，商谈能够激发云销售佣金；'
      }, {
        img: require('@/assets/img/page3.3.3.png'),
        title: '云销售招募服务',
        text: '外包企业（商户）委托平台提供云销售招募服务'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  z-index: 1;
  margin: 0.42rem -0.08rem 0 -0.08rem;
}
.pic {
  height: 2.02rem;
}
img {
 width: 100%;
}
.link {
  text-align: center;
  margin: 0.16rem 0 0 0;
  .title {
    margin-bottom: 0.16rem;
    .title-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.06rem;
    }
    .title-small {
      font-family: PingFangSC-Regular;
      color: #666666
    }
  }
  .forms1 {
    margin: 0.1rem;
    .form1 {
      position: relative;
      display: flex;
      align-items: center;
      height: 0.77rem;
      margin-bottom: 0.12rem;
      border-radius: 0.36rem;
      box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
      .form1_icon {
        padding: 0 0.15rem 0 0.23rem;
        width: 0.23rem;
        .form1_img {
          width: 100%;
        }
      }
      .form1_solid {
        height: 0.355rem;
        margin-right: 0.19rem;
        border: 0.005rem solid #D3D2D2
      }
      .form1_content {
        text-align: start;
        width: 2.45rem;
        .form1_title {
          margin-bottom: 0.03rem;
          font-family: PingFangSC-Medium;
          font-size: 0.13rem;
        }
        .form1_text {
          font-family: PingFangSC-Regular;
          font-size: 0.12rem;
          color: #8C8C8C;
        }
      }
    }
  }
}
.link2 {
  background-color: #f7f7f7;
  padding: 0.01rem;
  .link2_title {
    text-align: center;
    margin-top: 0.2rem;
    .link2_title-big {
      font-size: 17px;
      font-family: PingFangSC-Medium;
      margin-bottom: 0.06rem
    }
    .link2_title-small {
      font-size: 12px;
      font-family: PingFangSC-Regular;
      color: #666666
    }
  }
  .forms2 {
    display: flex;
    margin: 0.15rem 0 0 0.125rem;
    .form2 {
      text-align: center;
      width: 1.1rem;
      height: 3.2rem;
      margin-right: 0.1rem;
      background-color: #ffffff;
      border-radius: 0.08rem;
      box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
      .form2_icon {
        margin: 0.12rem 0 0 0.05rem;
        width: 1rem;
        .form2_img {
          width: 100%;
        }
      }
      .form2_content {
        margin: 0.05rem 0.12rem 0 0.11rem;
        .form2_title {
          height: 0.4rem;
          font-family: PingFangSC-Medium;
        }
        .form2_text {
          font-family: PingFangSC-Regular;
          color: #888888
        }
      }
      .form2_forw {
        position: absolute;
        border: 0.01rem solid #3974FB;
        width: 0.13rem;
        height: 0.13rem;
        border-radius: 50%;
        left: 0.48rem;
        bottom: 0.16rem;
        .forw2 {
          position: absolute;
          left: 0.01rem;
          font-size: 0.05rem;
        }
      }
    }
  }
}
</style>
